import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class FacilityInventoryService {

    async parkingList() {
        promise =  api.client.get(config.end_point.facility.parkings + `?paginate=1&per_page=10&airport_id=${config.airport_id}`);
        return promise;
    }

    async getReserved() {
        promise =  api.client.get(config.end_point.facility.reserve, {parking_id: 1});
        return promise;
    }

    store(data) {
        promise =  api.client.post(config.end_point.facility.parkings, data);
        return promise;
    }

    show(id) {
        promise =  api.client.get(config.end_point.facility.parkings + '/' + id);
        return promise;
    }

    update(id,data) {
        promise =  api.client.put(config.end_point.facility.parkings + '/' + id, data);
        return promise;
    }

    delete(id) {
        promise = api.client.delete(config.end_point.facility.parkings + '/' + id);
        return promise;
    }

    async list(data){
        promise =  api.client.get(config.end_point.facility.parkings,{params:  data});
        return promise;
    }
}

export const facilityInventoryService = new FacilityInventoryService();
