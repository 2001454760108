import {facilityInventoryService} from "@/services/facility/inventory/inventory.service";
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import {alertService} from "@/services/alert/alert.service";
import allLocales from '@fullcalendar/core/locales-all';
import { EventBus } from "@/event-bus";
import CrComponent from  "./car-room/list.component.vue";
export default {
    components: {
        FullCalendar, // make the <FullCalendar> tag available
        CrComponent
    },
    data() {
        return {
            data: {},
            dataReady: false,
            filter: {
                page: 10,
            },
            calendarOptions: {
                locales: allLocales,
                locale: this.$i18n.locale,
                plugins: [ dayGridPlugin, interactionPlugin ],
                initialView: 'dayGridMonth',
                selectable: false,
                selectOverlap: false,
                eventOverlap: false,
                car_room: []
            },
            calendarData: [],
            stopBooking: [],
            items: [
                { name: '101', next_reservation: '--', operation: 'Online' },
                { name: '102', next_reservation: '2022/9/10 10:00 - 2022/9/15 15:00', operation: 'Online' },
                { name: '103', next_reservation: '--', operation: 'Online' },
                { name: '104', next_reservation: '2022/9/10 10:00 - 2022/9/15 15:00', operation: 'Online' },
                { name: '105', next_reservation: '--', operation: 'Stop' }
            ]
        }
    },
    computed: {
        fields(){
            return [
                { key: 'no', label: '#', thClass: 'col-1' },
                { key: 'name', label: this.$t("facility.inventory.name"), thClass: 'col-3' },
                { key: 'next_reservation', label: this.$t("facility.inventory.next-reservation") },
                { key: 'operation', label: this.$t("facility.inventory.operation") },
            ]
        }
    },
    async mounted() {
        await this.list();

        EventBus.$on('changeLocal', ()=>{
            this.calendarData.forEach(item => {
                item.locale = this.$i18n.locale;
            });

            this.calendarOptions.locale = this.$i18n.locale;
        });
    },
    methods: {
        async list() {
            alertService.loading();
            await facilityInventoryService.parkingList()
            .then((response) => {

                this.data = response.data.data;
                this.dataReady = true

                this.$refs.cr.init_parking(this.data);
                let events = [];
                response.data.data.forEach(inventory => {
                    inventory.reservable_capacities.forEach(capacity => {
                        events.push({
                            start: capacity.date,
                            end : capacity.date,
                            title: capacity.capacity,
                            parkingId: inventory.id,
                            reserved: 0,
                        })
                    });

                

                    inventory.stop_booking.forEach(stop_booking => {
                        events.push({
                            start: stop_booking.date,
                            end : stop_booking.date,
                            title: stop_booking.capacity,
                            parkingId: inventory.id,
                            reserved: 0,
                        })

                        this.stopBooking.push({date: stop_booking.date,parking_id: inventory.id});    
                    });

                    this.setReserved(events,inventory.id, inventory.car_room);
                    
                    events = [];
                });
            })
            
        },

        async setReserved(events,id,car_room) {

            facilityInventoryService.getReserved()
                .then((response)=> {
                    if(typeof response.data[id] !== 'undefined') {
                        response.data[id].forEach(reserve => {
                            events.forEach(event => {
                                if(reserve.date == event.start) {
                                    event.reserved = reserve.total;
                                }
                            });
                        });
                    }
            });
            this.calendarOptions.car_room = car_room;
            this.calendarData.push({events,...this.calendarOptions, ...{eventContent: this.reRenderCalendar},...{locale : this.$i18n.locale}})

            this.$emit('calendar', !this.calendarData.length);
            alertService.close();
        },

        reRenderCalendar(arg) {

            this.stopBooking.forEach( list => {
                if(list.parking_id == arg.event.extendedProps.parkingId && list.date == arg.event.startStr){
                    $(`.parking-id-${list.parking_id}`).find(`[data-date='${arg.event.startStr}']`).css({"background":"#ffcccb"});
                }
            });

            return  { html:`<strong><span :style="color: ${arg.event.textColor} !important" class="text-primary event-value">${arg.event.extendedProps.reserved}</span>${arg.event.title}</strong>`}
        },

        async delete$(id) {

            var callback = async () => {
                await facilityInventoryService.delete(id)
                this.$router.go();
            }
            alertService.confirmation(callback, this.$t("facility.global.are-you-sure"), this.$t("facility.global.cannot-reverted"));

        },
    }

}
