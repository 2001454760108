import {alertService} from "@/services/alert/alert.service";
export default {
    components: {},
    data() {
        return {
            parking: [],
            rooms: []
        }
    },
    async mounted() {

    },
    methods: {
        async populate_rooms() {
            alertService.loading();
            this.rooms = [];
            let default_keys = {
                    duplicated: false,
                    next_reservation: {
                        entry_date: null,
                        entry_time: null
                    }
                }
            this.parking.forEach(async (value, key) => {
                default_keys[`exist_in_parking_id_${value.id}`] = false;
            });


            this.parking.forEach(async (value, key) => {

                    value.car_room.forEach((cr, key) => {


                        let input = {
                            ...default_keys
                        };
                        let exist = this.rooms.find(el => el.name == cr.name);

                        if (!exist) {
                            input.name = cr.name;
                            input.operation = cr.operation;
                            if(cr.next_reservation){
                                input.next_reservation = cr.next_reservation;
                            }

                            input[`exist_in_parking_id_${value.id}`] = true;
                            this.rooms.push(input);
                        } else {
                            let index = this.rooms.findIndex(el => el.name == cr.name);
                            this.rooms[index][`exist_in_parking_id_${value.id}`] = true;
                            this.rooms[index].duplicated = true;
                        }
                    });


                }
            );
            alertService.close();
        },
        async init_parking(data) {

            this.parking = data;

            await this.populate_rooms();
        }
    }
}